import { set } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import DeviceService from 'mobile-web/services/device';

import VendorService from './vendor';

export default class DigitalWalletService extends Service {
  // Service injections
  @service device!: DeviceService;
  @service vendor!: VendorService;

  // Untracked properties

  // Tracked properties
  @tracked isHybridApplePayAvailable = false;
  @tracked isHybridGooglePayAvailable = false;

  // Getters and setters
  updateIsHybridApplePayAvailable(isApplePay: boolean): void {
    set(this, 'isHybridApplePayAvailable', isApplePay);
  }

  updateIsHybridGooglePayAvailable(isGooglePay: boolean): void {
    set(this, 'isHybridGooglePayAvailable', isGooglePay);
  }

  get showHybridApplePayButton(): boolean {
    return this.isHybridApplePayAvailable;
  }

  get showHybridGooglePayButton(): boolean {
    return this.isHybridGooglePayAvailable;
  }

  /**
   * We rely on `useOloPay` for loading digital wallets
   * on the page in a timely manner. We need this check
   * in case the vendor changes to a non Olo Pay vendor.
   */
  get useOloPayFeatures(): boolean {
    return !!this.vendor.vendor?.settings.isOloPay;
  }

  // Lifecycle methods

  // Other methods
  isApplePayButtonPresent(isApplePay: boolean, noOtherPaymentMethods: boolean): boolean {
    return (
      (this.showOloPayDigitalWalletsButton(noOtherPaymentMethods) && isApplePay) ||
      this.showHybridApplePayButton
    );
  }

  isGooglePayButtonPresent(isGooglePay: boolean, noOtherPaymentMethods: boolean): boolean {
    return (
      (this.showOloPayDigitalWalletsButton(noOtherPaymentMethods) && isGooglePay) ||
      this.showHybridGooglePayButton
    );
  }

  showDigitalWalletsButton(noOtherPaymentMethods: boolean): boolean {
    return !this.device.isHybrid && !this.useOloPayFeatures && noOtherPaymentMethods;
  }

  showOloPayDigitalWalletsButton(noOtherPaymentMethods: boolean): boolean {
    return !this.device.isHybrid && this.useOloPayFeatures && noOtherPaymentMethods;
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'digital-wallet': DigitalWalletService;
  }
}
